/*
* ===================================================
*    Other
* ===================================================
*/

html {
    scroll-behavior: smooth;
}


.dropdown-item.active {
    font-weight: bold;
}

.pt-header-height {
    padding-top: $headerHeight;
}