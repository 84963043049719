/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/

/*
   Text utils 
   ------------------------
*/

.text-uppercase {
    @include uppercase;
}

.text-sm {
    font-size: $font-size-sm;
}

.text-base {
    font-size: $font-size-base;
}

.text-lg {
    font-size: $font-size-lg;
}

.text-xl {
    font-size: $font-size-xl;
}

.text-serif {
    font-family: $font-family-serif !important;
}

.text-shadow {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
    text-shadow: 2px 2px 2px rgba(theme-color('primary'), .1);
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-inherit {
    color: inherit;
}

/*
    Rounded corners - lg, sm 
   ------------------------
*/

.rounded-lg {
    border-radius: $border-radius-lg;
}

.rounded-sm {
    border-radius: $border-radius-sm;
}

.rounded-xl {
    border-radius: 10rem;
}

/*
    Responsive borders - border-[breakpoint]
   ------------------------
*/
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border#{$infix} {
            border: $border-width solid $border-color !important;
        }
    }
}

/*
    Opacity helpers - .opacity-[1-9]   
    ------------------------
*/


$opacity: 1 2 3 4 5 6 7 8 9 10;

@each $current-opacity in $opacity {
    .opacity-#{$current-opacity} {
        opacity: #{$current-opacity*0.1};
    }
}

/*
    Z-index helpers - .z-index-[10-50]   
    ------------------------
*/

$z-index: 10 20 30 40 50;

@each $current-z-index in $z-index {
    .z-index-#{$current-z-index} {
        z-index: #{$current-z-index};
    }
}

/*
     Letter spacing helpers - .letter-spacing-[0-5]
    ------------------------
*/

.letter-spacing-0 {
    letter-spacing: 0 !important;
}

$spacing: .1em .2em .3em .4em .5em;

@each $current-spacing in $spacing {
    $i: index($spacing, $current-spacing);

    .letter-spacing-#{$i} {
        letter-spacing: #{$current-spacing};
    }
}

/*
     Colours and backgrounds
    ------------------------
*/


@each $color,
$value in $theme-colors {
    .text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    a.text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    .bg-#{$color}-light {
        background-color: theme-color-level($color, -11);
    }

    .border-#{$color} {
        border-color: $value !important;
    }
}

@each $color,
$value in $grays {
    .bg-gray-#{$color} {
        background: $value !important;
    }

    .text-gray-#{$color} {
        color: $value;
    }
}

.bg-primary-100 {
    background: $primary-100;
}

@each $color,
$value in $theme-colors {
    .badge-#{$color}-light {
        color: $value;
        background-color: theme-color-level($color, -11);
    }
}

/*
     Transparent overlays
    ------------------------
*/

.overlay-content {
    position: relative;
    z-index: 20;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            position: relative;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                background: #fff;
            }
        }
    }

    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            &::after {
                transition: $transition-fade;
                opacity: 0;
            }
        }
    }

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix}:hover {
        @include media-breakpoint-up($next) {
            &::after {
                opacity: .4;
            }
        }
    }

    .light-overlay#{$infix}-0,
    .overlay-hover-light#{$infix}-0:hover {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }

    .dark-overlay#{$infix} {
        position: relative;

        @include media-breakpoint-up($next) {
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                opacity: .4;
                background: #000;
            }
        }
    }

    .dark-overlay#{$infix}-0 {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }
}

/*
     Other
    ------------------------
*/


.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.td-none {
    text-decoration: none !important;
}

.tile-link {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 30;
}

.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

img.bg-image {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.shadow-0 {
    box-shadow: none !important;
}

.left-box {
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.7);
}

.hover-scale {
    transition: $transition-base;

    &:hover {
        transform: scale(1.05);
    }
}

.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

img.bg-image {
    font-family: 'object-fit: cover;';
    object-fit: cover;
}

.bg-cover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.bg-cover-left {
        background-position: left center;
    }

    &.bg-cover-right {
        background-position: right center;
    }
}



.full-screen {
    height: calc(100vh - #{$navbarHeight});
}

.mh-full-screen {
    min-height: 100vh;

    // hack for IE 11
    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
        height: 100vh;
    }
}

.mh-full-screen-with-header {
    min-height: calc(100vh - #{$headerHeight} - 2rem);

    // hack for IE 11
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        height: calc(100vh - #{$headerHeight} - 2rem);
    }
} 


// responsive collapse behaviour
@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .expand#{$infix} {
        @include media-breakpoint-up($next) {
            display: block !important;
        }
    }
}

.block-toggler {
    display: block;
    position: relative;
    @include uppercase;
    color: $text-muted;

    &[aria-expanded="true"],
    &:focus {
        color: $body-color;
        text-decoration: none;
    }

    &:focus,
    &:hover {
        color: $body-color;
        text-decoration: none;
    }

    &[data-toggle="collapse"] {
        &::before {
            content: '\f105';
            display: inline-block;
            transform: translateY(-50%);
            font-family: "Font Awesome\ 5 Free";
            font-weight: 900;
            position: absolute;
            top: 50%;
            right: 0;
        }
    }

    &[aria-expanded="true"] {
        &::before {
            content: '\f107';
        }
    }
}

.svg-icon {
    width: 18px;
    height: 18px;
    stroke: currentColor;
    stroke-width: 3;
    vertical-align: text-bottom;
    --layer1: currentColor;
    --layer2: currentColor;

    &.svg-icon-light {
        stroke-width: 2;
    }

    &.svg-icon-heavy {
        stroke-width: 4;
    }
}


// auto width for smallest viewports
.w-xs-auto {
    @include media-breakpoint-down(xs) {
        width: auto !important;
    }
}
// fixes for IE11
@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {

    .card-img-top,
    .card-img-bottom,
    .card-img {
        height: 100%;
    }
}