.snipcart-layout {
	/* Colors */
	--color-buttonPrimary: #fff;
	--color-buttonSecondary: #fff;
	--color-buttonSecondary-hover: #fff;
	--color-icon: #313030;

	/* Background colors */
	--bgColor-buttonPrimary: #191919;
	--bgColor-buttonPrimary-hover: #313030;
	--bgColor-buttonSecondary: #191919;
	--bgColor-buttonSecondary-hover: #313030;
}
