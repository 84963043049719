/*
* ===================================================
*     Homepage Elements
* ===================================================
*/

// Homepage full-screen slider

.home-full-slider {
    margin-bottom: 10px;
    height: 600px;
    @include media-breakpoint-up(md) {
        height: 100vh;
    }
    .swiper-slide {
        padding-top: $headerHeight;
        padding-bottom: $headerHeight * 0.5;
        min-height: 600px;
        height: auto;
    }

    @include media-breakpoint-up(md) {
        .swiper-slide {
            min-height: 100vh;
        }        
        p.lead {
            font-size: $lead-font-size;
        }
    }
}

// Split-screen homepage

.split-screen-column {
    display: flex;
    align-items: center;
    background: center center $black no-repeat; 
    background-size: cover; 
    color: $white;
    text-align: center;
    height: auto;
    @include media-breakpoint-up(lg) {
        height: 100vh;
    }
}

@include media-breakpoint-down(md) {
    h1.split-screen-heading {
        font-size: $h1-font-size * .5;
    }
    h2.split-screen-heading {
        font-size: $h2-font-size * .5;
    }
    h3.split-screen-heading {
        font-size: $h3-font-size * .5;
    }
}

// Product slider

.product-slider {
    position: relative;
}

.product-slider-item {
    margin-left: .8rem;
    margin-right: .8rem;
}


// Parallax homepage

.parallax-section {
    padding-top: 14rem;
    padding-bottom: 14rem;
}

.circle-slider {
    margin-bottom: 10px;
    height: 600px;
    @include media-breakpoint-up(md) {
        height: 100vh;
        min-height: 600px;
    }
    .swiper-slide {
        display: flex;
        align-items: flex-end;
        background: $gray-100;
        padding-top: 80px;
    }
}

.circle-slider-bg {
    height: 100%;
    background-size: contain !important;
    @include media-breakpoint-down(sm) {
        background-position: center bottom !important; 
    }
}